// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.news-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-top: 0.78rem;
  padding-bottom: (160rem / 100);

  .news-content {
    @extend %inside-box;
    position: relative;
  }
}

// 被激活的类型样式
.tabActive {
  // background-color: #761F1E;
  background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
    no-repeat !important;
  background-size: 100% 100% !important;
  color: #fff !important;
}

.news-content-select {
  float: right;

  .el-select:nth-child(2) {
    margin-left: (30rem / 100);
  }
}

// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.activity-container {
  @extend %out-box;

  .activity-content {
    @extend %inside-box;

    .activity-tab-content {
      img {
        width: (315rem / 100);
        height: (651rem / 100);
        float: left;
      }

      &-item {
        width: (380rem / 100);
        height: (350rem / 100);
        float: left;
        background-color: #fff;
        margin-right: (30rem / 100);
        margin-bottom: (60rem / 100);
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #e8e8e8;

        &:hover {
          box-shadow: 0 (3rem / 100) (6rem / 100)
            rgba($color: #c5c5c5, $alpha: 0.16);
        }

        .box {
          float: left;
          width: 100%;
          box-sizing: border-box;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &-title {
          width: 100%;
          font-size: (20rem / 100);
          margin-bottom: (17rem / 100);
          color: #0a0a0a;

          p {
            overflow: hidden;
            //   white-space: nowrap;
            //   text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        &-desc {
          p {
            font-size: (16rem / 100);
            color: #a7a7a7;
            font-family: Source Han Sans CN;
            margin-bottom: (11rem / 100);
            float: right;
          }

          img {
            width: (13rem / 100);
            height: (16rem / 100) !important;
            margin-right: (10rem / 100);
          }
        }

        img {
          height: (223rem / 100);
        }
      }
    }
  }
}

.news-content-pic {
  width: 6.6rem;
  height: 1.85rem;
  position: absolute;
  top: -0.78rem;
  right: 0;

  img {
    width: 100%;
    height: 100%;
  }
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-subSwitch {
  display: flex;
  align-items: center;
  margin-bottom: 0.28rem;

  &-title {
    font-size: 0.16rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #761f1e;
    line-height: 0.16rem;
    margin-right: 0.1rem;
  }

  &-choose-item {
    display: flex;

    li {
      padding: 0.08rem 0.34rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li2.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 0.3rem;
      cursor: pointer;
    }
    .imgType{
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
      no-repeat;
      background-size: 100% 100%;
    }
  }
}

.tab-data {
  font-size: 0.14rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #273143;
  line-height: 0.2rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}
