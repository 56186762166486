
.list-container {
  &-ul {
    width: 12rem;
    margin: 0 auto;
    position: relative;
    padding: 0.36rem 0.3rem;
    background-color: #fff;
    .border-top,
    .border-bottom {
      width: 100%;
      position: absolute;
      left: 0;
    }
    .border-top {
      top: 0;
    }
    .border-bottom {
      bottom: 0;
    }
    li {
      // height: 1.49rem;
      padding: 0.2rem 0;
      border-bottom: 0.01rem solid #d8d8d8;
      cursor: pointer;
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &-title {
      display: flex;
      align-items: center;
      p {
        width: 11.15rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.18rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: var("--color");
        line-height: 0.28rem;
      }
      .titleActive {
        color: #cd9e56;
      }
      .partyActive {
        color: #761f1e;
      }
    }
    &-abstract {
      margin: 0.11rem 0;
      p {
        width: 11.4rem;
        font-size: 0.14rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #273143;
        line-height: 0.22rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    &-time {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #56657f;
      line-height: 0.14rem;
    }
  }
}
.list-main-noContent {
  width: 100%;
  height: 5.06rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-img {
    width: 2.18rem;
    height: 2.86rem;
    margin-bottom: 0.24rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-size: 0.16rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #273143;
    line-height: 0.16rem;
  }
}
